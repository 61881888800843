<template>

    <div v-if="view === 'mobile'" class="bg-filters rounded-lg font-thin relative shadow-md mb-2">
        
        <div v-if="category == 'lastest'" class="">

            <div class="h-72 p-2 flex flex-col">

                <div class="h-auto max-h-16 mt-1">
                    <span class="text-sm font-semibold truncate-3">{{ data.title }}</span>
                </div>

                <div v-if="data.image" class="h-24 bg-white mt-1 rounded-lg" :style="'background-image: url(' + imageRoute + data.image + '); ' + 'background-repeat: no-repeat; background-size: cover'">                    </div>

                <div class="flex-1 min-h-0 mt-1">
                    <span class="text-font-gray text-xs" v-html="data.body"></span>
                </div>

                <div class="h-6 w-full px-2 flex flex-row justify-end items-center" @click="openFile(data.file, 'file')">
                    <i v-show="data.file" class="mdi text-font-gray cursor-pointer" :class="extension(data.file)"></i>
                </div>

            </div>

        </div>
        
        <div v-else class="h-full w-full flex flex-col">

            <div class="h-32 flex flex-row p-2">

                <div v-if="data.image" class="h-24 w-24 rounded-lg mr-2 bg-center"
                    :style="'background-image: url(' + imageRoute + data.image + '); ' + 'background-repeat: no-repeat; background-size: cover'">
                </div>

                <div class="h-full flex-1 flex flex-col">

                    <div class="h-4 flex flex-row justify-end items-center">
                        <span class="text-3xs text-warning">{{ data.date | moment('DD / MM / YYY') }}</span>
                    </div>

                    <div class="h-auto max-h-8 mb-1">
                        <p class="text-xs font-semibold leading-none truncate-2">{{ data.title }}</p>
                    </div>

                    <div class="flex-1 min-h-0 overflow-hidden mb-1">
                        <p class="text-3xs text-font-gray" v-html="data.body"></p>
                    </div>

                    <div class="h-4 flex flex-row justify-end items-center">

                        <i v-if="data.link !== ''" class="mdi mdi-open-in-new text-font-gray cursor-pointer text-xs mr-2" @click="goLink(data.link)"></i>
                        <i v-if="data.file !== ''" class="mdi text-xs cursor-pointer" :class="extension(data.file)" @click="openFile(data.file)"></i>

                    </div>

                </div>

            </div>

            <!-- <div class="px-2 flex flex-row mt-2 justify-end items-center py-2 ml-1">
                <span class="text-xs font-semibold truncate-2">{{ data.title }}</span>
                <i v-if="data.link && isValid(data.link)" class="mdi mdi-open-in-new text-font-gray cursor-pointer text-xs" @click="goLink(data.link)"></i>
            </div>
            
            <div class="py-1 ml-3">
                <span class="text-font-gray text-xs" v-html="data.body"></span>
            </div>
            
            <div class="w-full px-2 flex flex-row justify-end mb-2" @click="openFile(data.file, 'file')">
                <i v-show="data.file" class="mdi text-font-gray cursor-pointer" :class="extension(data.file)"></i>
            </div> -->

        </div>
    
    </div>

    <div v-else-if="select !== data" class="min-h-20 max-h-28 box mb-4 flex flex-row justify-start items-center p-4 font-thin leading-normal items-center relative cursor-pointer hover:bg-orange-15 shadow-md" @click="selected(data)">

        <div v-show="data.image" class="h-20 w-20 overflow-hidden rounded-lg bg-module shadow-xl">
            <img class="w-full h-full object-cover bg-center" :src="imageRoute + data.image"/>
        </div>

        <div class="h-full flex flex-1 min-w-0 px-2 flex-col">

            <div class="h-auto flex flex-row justify-end items-center">
                <span class="text-xxs text-font-gray">{{data.date | moment('DD/MM/YYYY')}}</span>
            </div>

            <div class="flex flex-1 min-h-0 w-full overflow-hidden flex flex-col justify-center items-start">

                <span class="truncate font-semibold">{{data.title}}</span>
                <span class="text-xs text-font-gray truncate-3" v-html="data.body"></span>

            </div>

        </div>

    </div>

</template>

<script>
export default {

    props: ['data', 'view', 'select', 'category'],
    methods: {
        goLink(link) { this.$goLink(link) },
        isValid(link) { return this.$is_url(link) },
        extension(file) {
            let result = ''
            result += this.$iconByExtension(file)
            return result
        },
        selected(option){
            this.$emit('select',option)
        },
        openFile(file, type) { this.$openFile(file, type) }
    },
    computed:{
        imageRoute() { return this.$imageRoute },
    }
}
</script>